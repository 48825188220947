.content {
    background-color: transparent;
    margin: 4px;
}

.sharedAccount {
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
}

.shareCamera {
    padding-top: 12px;
    padding-right: 12px;
}

.shareOptionSwitch {
    width: 100%;
}

.optionSwitch {
    float: right !important;
}