.hd_image {
    position: absolute;
    bottom: 1px;
    left: 1px;
    color: #dc6300;
    background-color: #0006;
    font-size: 25px !important;
    width: 25px;
}

.img_video {
    position: absolute;
    bottom: 1px;
    left: 1px;
    color: #dc6300;
    background-color: #0006;
    font-size: 25px !important;
}

.img_video_thumb {
    position: absolute;
    bottom: 1px;
    left: 25px;
    color: #dc6300;
    background-color: #0006;
    font-size: 25px !important;
}
