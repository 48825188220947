.content {
    background-color: #fff;
    height: calc(100vh - 64px);
    overflow-y: scroll;

    input {
        width: 100%;
    }
}

.leftMap {
    margin-left: -50px;
    width: calc(25% + 50px) !important;
    flex-basis: auto !important;
    max-width: calc(25% + 50px) !important;
}

.containerFloat {
    position: absolute;
    height: calc(100vh - 64px);
    width: 100%;
    display: flex;

    a {
        margin: auto 32px 32px auto;
        z-index: 1000;
    }
}

.control {
    width: 100%;
}

.divTimeLapse {
    margin-bottom: 0px !important;
}

.textMuted {
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
}

.divFooter {
    button {
        display: block;
        margin-left: auto;
    }
}

.divBottom {
    text-align: center;
}

.divBottomMobile {
    text-align: center;

    .divLine {
        margin: 4px auto;
    }
}

.divBottomHeader {
    .divTitle {
        display: flex;
        align-items: flex-end;

        .labelTitle {
            margin-right: auto;
            margin-bottom: 0;
        }

        .labelVersion {
            margin-left: auto;
            font-weight: bolder;
        }
    }

    .divFirmware {
        display: flex;
        align-items: center;

        .labelTitle {
            margin-right: auto;
        }

        .labelFirmware {
            width: 100%;
        }

        .image {
            height: 20px;
            display: block;
            margin-right: 0px;
            margin-left: auto;
        }
    }
}

.divBottomContent {
    display: flex;

    .divCameraType {
        text-align: center;
    }

    .divText {
        width: 100%;
    }

    .divFirmware {
        display: flex;
        align-items: center;

        .labelTitle {
            margin-right: auto;
        }

        .labelFirmware {
            width: 100%;
        }

        .image {
            height: 20px;
            display: block;
            margin-right: 0px;
            margin-left: auto;
        }
    }

    .divImages {
        margin-left: auto;
        display: flex;
        // color: #DC6300 !important;

        .image {
            height: 30px;
        }

        .divMemory {
            text-align: center;
            margin-right: 8px;

            p {
                margin: 0;
            }

            .image {
                height: 40px;
            }
        }

        .divCard {
            text-align: center;

            p {
                margin: 0;
            }

            .image {
                height: 40px;
            }
        }
    }
}

.border_none {
    border-collapse: collapse;
    border: none;
}

.border_none td {
    border: 1px solid #DC6300;
}

.border_none tr:first-child td {
    border-top: none;
}

.border_none tr:last-child td {
    border-bottom: none;
}

.border_none tr td:first-child {
    border-left: none;
}

.border_none tr td:last-child {
    border-right: none;
}

.td_title {
    padding: 6px 8px;
}

.td_value {
    text-align: center;
    color: gray;
    padding: 6px 8px;
    min-width: 80px;
}

.iconSignal {
    color: #DC6300 !important;

}