.videoModoal {
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
}

.tagInput {
    display: flex;
}

.new_image {
    position: absolute;
    top: 5px;
    left: 5px;
    color: #dc6300;
    font-size: 14px !important;
}

.divDetail {
    display: flex;

    .divStar {
        margin-left: auto;
        margin-top: auto;
    }
}

.cardCover {
    .checkBox {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    position: relative;

    .image {
        width: 100%;
        display: inline;
        .image {
            height: 100%;
        }
    }

    .img_antler {
        position: absolute;
        top: 0px;
        left: 10px;
        width: 40px;
        z-index: 10;
    }

    .imageCategory {
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 2px 5px;
        color: #aaa;
        background-color: #fffc;
        border: solid 1px #dedede;
        display: inline-flex;
        align-items: center;
    }

    .imageCategory div {
        width: 15px;
        height: 15px;
        margin-right: 3px;
        border: solid 1px #dedede;
    }

}

.videoCard {
    width: 100%;
    height: 100%;
}

.labelCamera {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    margin-left: 3px;
}

.labelTime {
    color: gray;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 12px;
    margin-left: 3px;
}