.divHeader {
    display: flex;
    align-items: center;

    .divDay {
        margin-right: auto;
    }
}

hr {
    color: lightgray;
    border-color: lightgray;
    background-color: lightgray;
    height: 1px;
    border-width: 0;
}

.firstWeekday {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
}

.firstWeekdate {
    font-weight: bold;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    color: gray;
}

.img_moon {
    max-width: 40px;
    width: 100%;
}

.best_time {
    color: gray;
    text-align: end;
}

.sunContainer {
    display: flex;
    position: relative;
    height: 75px;
    margin-top: -75px;
    margin-left: 27px;
    margin-right: 24px;
    img {
        width: 25px;
        height: 25px;
    }
    div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}