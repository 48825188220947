.wrapper {
    padding: 8px;
    .formInputLabel {
        margin-bottom: 4px;
    }
    .formInputWrapper {
        display: flex;
        align-items: center;
        .checkBox {
            // width: 100px;
            // height: 100px;
        }
    }
}
.accordion_header {
    margin-left: -12px;
}
.accordian_panel {
    border: none !important;
    .categoryItem {
        font-size: 16px;
        color: #8e95a0 !important;
        cursor: pointer;
        transition: color 0.6s cubic-bezier(0.55, 0, 0.1, 1);
        margin-bottom: 4px;
        &:hover {
            color: rgb(40, 60, 86) !important;
        }
        &.selected {
            color: rgb(40, 60, 86) !important;
        }
    }
}
.selected_filter_Option {
    padding: 2px 10px;
    border-radius: 50px;
    border: solid 1px rgb(40, 60, 86);
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.editTrash {
    width: 100%;
    margin-bottom: 20px;
}

.categoryGroup {
    width: 100%;
    margin-bottom: 24px !important;
    text-align: center;
}

.categoryBtn {
    width: 100%;
}
.xl_img {
    width: 15px;
    margin-bottom: 2px;
    margin-right: 5px;
}