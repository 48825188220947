.mediaboxWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    flex-flow: column;

    video {
        display: block;
    }

    figure {
        display: block;
    }

    .image {
        max-width: 1000px;
        width: 100%;
    }

    .leftArrow,
    .rightArrow {
        cursor: pointer;
        padding: 16px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        user-select: none;
        z-index: 1000;

        &:hover {
            background-color: rgba(0, 0, 0, 0.8);
        }
    }

    .actionBarLeft {
        position: absolute;
        top: 50%;
        z-index: 2;
    }

    .actionBarRight {
        position: absolute;
        top: 50%;
        right: 0px;
        z-index: 2;
    }

    .divStar {
        position: absolute;
        bottom: 50px;
        right: 10px;
        z-index: 1000;
        display: flex;
        align-items: center;
    }

    .cameraInfoText {
        height: 60px;
        width: 100%;
        background-color: black;
        color: white;
        text-align: center;

        .infoCol {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 40px;
                margin: 8px;
            }
        }
    }

    .editDiv {
        background-color: black;
        width: 100%;
        padding-bottom: 5px;
        align-items: center;

        .editField {
            width: 100%;
            padding-left: 5px;
            padding-right: 5px;
        }

        .editTrash {
            width: 95%;
        }
    }

    .react_transform_wrapper {
        width: 100%;
    }

    .react_transform_component {
        width: 100%;
    }
}

.imageZoom {
    position: absolute;
    /* bottom: 0px; */
    z-index: 2;
    background: #fffc;
}