.divParent {
    display: flex;
}

.textCategory {
    min-width: 80px;
}

.textPriority {
    min-width: 160px;
}

.divSpace {
    margin-left: auto;
    margin-right: auto;
}

.divSlider {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.textEnable {
    text-align: center;
}

.divEnable {
    min-width: 60px;
    margin-left: 4px;


}