.divParent {
    display: flex;
}

.textCategory {
    min-width: 80px;
}

.divEnable {
    min-width: 60px;


}