.sharedAccountCard {
    margin-bottom: 8px !important;
    // transition: all 0.3s ease;
}

// .sharedAccountCard:hover {
//     transform: translateY(-3px); /* Move the card up */
//     box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
//     border-color: #40a9ff; /* Change border color on hover */
// }

.sharedAccountCard:last-of-type {
    margin-bottom: 0px !important;
}

.groupItemIcon {
    font-size: 16px;
    margin-right: 12px;
}
.groupItemIcon:last-child {
    margin-right: 0px;
}