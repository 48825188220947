.content {
    background-color: transparent;
    margin: 4px;
}

.containerFloat {
    position: fixed;
    bottom: 0;
    right: 0;
    button, a {
        margin: auto  16px  16px auto;
        z-index: 900;
    }
}

.imageFilter {
    margin: 20px 0 0 15px;
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    overflow-y: auto;
    max-height: calc(100vh - 90px);
    top: 68px;
}

.imageScrollContainer {
    max-height: calc(100vh - 90px);
    overflow-y: scroll;
}

.groupDivider {
    color: #dc6300 !important;
    font-weight: bold !important;
}

.drawerHandle {
    position: fixed;
    top: 140px;
    right: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 16px;
    text-align: center;
    background: #222d32;
    color: white;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    pointer-events: auto;
}

.categoryGroup {
    width: 100%;
    padding: 20px 16px 0px 16px !important;
    text-align: center;
}

.categoryBtn {
    width: 100%;
}

.mediaModal {
    top: 48px !important;
}

.pagination {
    margin-bottom: 45px !important;
    margin-left: 12px !important;
}

.noImage {
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
}