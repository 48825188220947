
.divButton {
    display: flex;
    justify-content: end;
}

.divSelectFeeder {
    align-items: center;
    .select {
        width: auto;
        min-width: 150px;
    }
    margin-bottom: 8px;
}

.subContent {
    margin: 12px;
}
