.divLeft {
    flex: 1;
}

.divBody {
    display: flex;
}

.labelIMEI {
    color: #DC6300 !important;
}

.sunContainer {
    display: flex;
    position: relative;
    margin-top: -75px;
    margin-left: 27px;
    margin-right: 16px;

    img {
        width: 25px;
        height: 25px;
    }

    div {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
}

.chart_sun {
    width: 16px;
    height: 16px;
    display: block;
}

.chart_xLabel {
    margin: 0;
    font-size: 6px;
    display: block;
    width: 100%;
    text-align: center;
}

.imageContainer {
    display: flex;
    // margin-top: 70px;
    text-align: justify;
    margin-left: 32px;
    margin-right: 16px;

    .img_sun_first {
        width: 24px;
        height: 24px;
        display: inline-block;
    }

    .img_sun {
        width: 24px;
        height: 24px;
        margin-left: auto;
        display: inline-block;
    }

    p.second {
        margin-left: auto;
        display: inline-block;
    }

    p.first {
        display: inline-block;
    }
}